<template>
  <div
    class="pa-4"
    v-if="isLoading"
  >
    <v-progress-linear
      indeterminate
      color="primary"
    />
    <h6 class="text-subtitle-2 text-center mt-3">Carregando...</h6>
  </div>
  <v-container
    v-else
    fluid
  >
    <h1 class="text-h5 primary--text pa-3 mb-7">Dashboard</h1>

    <v-row
      class="mb-5"
      justify="center"
    >
      <v-col
        cols="5"
        align="center"
      >
        <TwoColumnCard :items="columns" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(item, idx) in tables"
        :key="idx"
        cols="12"
        md="6"
      >
        <TableCard
          :title="item.title"
          :icon="item.icon"
          :headers="item.headers"
          :items="item.items"
          :color="item.color"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission.js'
export default {
  components: {
    TwoColumnCard: () => import('@/components/dashboard/TwoColumnCard'),
    TableCard: () => import('@/components/dashboard/TableCard'),
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    tables: [
      {
        title: 'Pedidos por status',
        color: 'primary-color',
        icon: 'fas fa-vote-yea',
        headers: [
          { text: 'Status', value: 'status', sortable: false },
          {
            text: 'Pedidos',
            value: 'order_amount',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Total',
            value: 'orders_total',
            align: 'end',
            sortable: false,
          },
        ],
        items: [],
      },
      {
        title: 'Pedidos por ano - ',
        color: 'accent-color',
        icon: 'fas fa-vote-yea',
        headers: [
          { text: 'Mêses', value: 'month', sortable: false },
          {
            text: 'Pedidos',
            value: 'order_amount',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Total',
            value: 'orders_total',
            align: 'end',
            sortable: false,
          },
        ],
        items: [],
      },
    ],
    columns: [
      {
        title: 'Orçamentos',
        amount: 0,
      },
      {
        title: 'Pedidos',
        amount: 0,
      },
    ],
    isLoading: true,
  }),
  methods: {
    getDash() {
      this.$api
        .get(`orders/dashboard`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.dash = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.setOrders()
          this.setTop()
          this.isLoading = false
        })
    },
    setOrders() {
      const dateTime = new Date()
      //Tabela de Pedido por status
      this.tables[0].items = this.dash.orders.tables
      //Tabela de Pedidos por ano
      this.tables[1].title = this.tables[1].title + dateTime.getFullYear()
      this.tables[1].items = this.dash.orders.year
    },
    setTop() {
      this.columns[0].amount = this.dash.budgets.amount
      this.columns[1].amount = this.dash.orders.amount
    },
  },
  created() {
    this.getDash()
  },
}
</script>
